import React, { Component, ReactNode } from 'react';
import { StylesConfig } from 'react-select';
import DropdownMenu from '../complements/dropdown-menu';

interface Props {
  dropdownTitle: string;
  accessibilityLabel: string;
  options: Record<any, any>[];
  categories: {
    options: Record<any, any>[];
    value: string;
    title: string;
  }[];
  dropDownStyle: StylesConfig<any, any, any>;
}

class DisplayOpenPositions extends Component<Props> {
  render(): ReactNode {
    return (
      <div className="container max-width bg-white pt-4 laptop-standard:pt-20">
        <div className="place-content-center pb-10">
          <DropdownMenu
            title={this.props.dropdownTitle}
            accessibilityLabel={this.props.accessibilityLabel}
            options={this.props.options}
            categories={this.props.categories}
            selectStyle={this.props.dropDownStyle}
          />
        </div>
      </div>
    );
  }
}

export default DisplayOpenPositions;
