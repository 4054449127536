import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { GetCareersDataQuery } from '../../graphql-types';
import DisplayOpenPositions from '../components/careers/display-open-positions';
import MessageForFailures from '../components/careers/message-for-failures';
import Layout from '../components/layout';
import Masthead from '../components/masthead';
import styledCareers from '../styles/styledCareers';
import Masthead2022 from '../components/masthead2022';
import Disclaimer from '../components/careers/disclaimer';

const careersData = {
  'dropdown': {
    'title': 'All Departments',
  },
  'visaTypes': [
    { 'value': 'visa1', 'label': 'Visa 1' },
    { 'value': 'visa2', 'label': 'Visa 2' },
  ],
};

const messagesForNoPositions = {
  'title': 'There are currently no open positions.',
  'message':
    'But, we would love to hear from you. Feel free to send your portfolio to <a class="link-style" href="mailto:careersla@deutschinc.com" target="_blank">careersla@deutschinc.com</a>.',
};

const SEO = {
  'title': 'Careers',
  'author': 'Deutsch LA',
  'keywords': 'Deutsch LA, deutsch',
};

const GET_CAREERS_DATA = graphql`
  query GetCareersData {
    allNodeMasthead(
      filter: { relationships: { field_page_reference: { name: { eq: "Careers" } } } }
    ) {
      ...CompleteMastheadsFields
    }
    allNodeMasthead2022(
      filter: { relationships: { field_page_reference: { name: { eq: "Careers" } } } }
    ) {
      ...CompleteMasthead2022Fields
    }
    allCareer {
      nodes {
        id
        slug
        title
        department
        created_at
      }
    }
  }
`;
const Careers: React.FC = () => {
  const data = useStaticQuery<GetCareersDataQuery>(GET_CAREERS_DATA);
  const mastheadData = data.allNodeMasthead.nodes[0];
  const masthead2022Data = data.allNodeMasthead2022.nodes[0];

  const { allCareer } = data;
  const { nodes } = allCareer;
  const departments = nodes.reduce<{ value: string; label: string }[]>((result, element) => {
    if (!result.find((department) => department.value === element.department)) {
      result.push({
        value: element.department ?? '',
        label: element.department ?? '',
      });
    }
    return result;
  }, []);
  if (departments.length) {
    departments.sort((a, b) => {
      const firstValue = a.label.toUpperCase();
      const secondValue = b.label.toUpperCase();
      return firstValue < secondValue ? -1 : firstValue > secondValue ? 1 : 0;
    });
    departments.unshift({ 'value': 'all', 'label': 'All Departments' });
  }
  const jobPositions = nodes
    .reduce<{ title: string; value: string; options: { name: string; ref: string }[] }[]>(
      (result, element) => {
        const categoryIndex = result.findIndex((category) => category.value === element.department);

        if (categoryIndex === -1) {
          result.push({
            'title': element.department ?? '',
            'value': element.department ?? '',
            'options': [
              {
                'name': element.title ?? '',
                'ref': element.slug ?? '',
              },
            ],
          });
        } else {
          result[categoryIndex].options.push({
            'name': element.title ?? '',
            'ref': element.slug ?? '',
          });
        }
        return result;
      },
      []
    )
    .sort((jobPositionA, jobPositionB) => (jobPositionA.title > jobPositionB.title ? 1 : -1));

  return (
    <Layout seo={SEO}>
      {mastheadData && <Masthead mastheadData={mastheadData} />}
      {masthead2022Data && <Masthead2022 masthead2022Item={masthead2022Data} />}
      {departments.length ? (
        <>
          <DisplayOpenPositions
            dropdownTitle={careersData.dropdown.title}
            accessibilityLabel="Select a department"
            options={departments}
            categories={jobPositions}
            dropDownStyle={styledCareers}
          />
          <Disclaimer />
        </>
      ) : (
        <MessageForFailures
          title={messagesForNoPositions.title}
          message={messagesForNoPositions.message}
        />
      )}
    </Layout>
  );
};

export default Careers;
