import React, { Component, ReactNode } from 'react';
import Select, { components, PlaceholderProps, IndicatorProps, StylesConfig } from 'react-select';
import { Props as StateManagerProps } from 'react-select/src/stateManager';
import Link from 'gatsby-link';
import LeftArrow from '../../images/icons/ARROW.svg';
import { CaretDownIcon, CaretUpIcon } from '../../images/icons/font-awesome-icons';
import './_dropdown-menu.css';

const Placeholder: React.FC<PlaceholderProps<any, any, any>> = (props) => {
  return <components.Placeholder {...props} />;
};

const DropdownIndicator: React.FC<IndicatorProps<any, any, any>> = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      {!props.selectProps.menuIsOpen ? <CaretDownIcon /> : <CaretUpIcon />}
    </components.DropdownIndicator>
  );
};

interface Props {
  title: string;
  accessibilityLabel: string;
  selectStyle?: StylesConfig<any, any, any>;
  categories: {
    options: Record<any, any>[];
    value: string;
    title: string;
  }[];
  options: ReadonlyArray<any>;
}

interface State {
  display: Record<string, any>;
}

class DropdownMenu extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      display: props.categories.reduce<Record<string, any>>((list, elements) => {
        list[`${elements.value}-class`] = 'block';
        return list;
      }, {}),
    };
  }

  handleChange: StateManagerProps<any, any, any>['onChange'] = (event) => {
    const display = Object.keys(this.state.display).reduce<Record<string, any>>((list, key) => {
      if (key === `${event.value}-class` || event.value === 'all') {
        list[key] = 'block';
        return list;
      }
      list[key] = 'hidden';
      return list;
    }, {});
    this.setState({ display });
  };

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  onFocus = ({ focused, isDisabled }: any) => {
    const msg = `You are currently focused on option ${focused.label}${
      isDisabled ? ', disabled' : ''
    }`;
    return msg;
  };

  render(): ReactNode {
    return (
      <div
        className="max-width tablet:mx-40 laptop-standard:mx-10
        desktop-standard:mx-56 desktop-large:mx-40"
      >
        <div className="container careers-dropdown-departments-container">
          <Select
            aria-label={this.props.accessibilityLabel}
            ariaLiveMessages={{ onFocus: this.onFocus }}
            closeMenuOnSelect
            components={{
              Placeholder,
              DropdownIndicator,
              'IndicatorSeparator': () => null,
            }}
            placeholder={this.props.title}
            onChange={this.handleChange}
            styles={this.props.selectStyle}
            options={this.props.options}
          />
        </div>
        <div className="careers-categories-container">
          {this.props.categories.map((category, index) => {
            return (
              <div
                className={`py-6 ${this.state.display[`${category.value}-class`]}`}
                key={`careers-container-${index}`}
              >
                <p className="careers-category-title">{category.title}</p>
                {category.options.map((option, dropdownIndex) => {
                  return (
                    <div className="py-2" key={`careers-drop-${dropdownIndex}`}>
                      <Link to={option.ref}>
                        <div className="careers-category-option-container">
                          <p className="careers-category-option-label-format">{option.name}</p>
                          <div className="lateral-padding-careers-container">
                            <LeftArrow className="careers-margin-lateral-arrow" />
                          </div>
                        </div>
                      </Link>
                    </div>
                  );
                })}
              </div>
            );
          })}
        </div>
      </div>
    );
  }
}

export default DropdownMenu;
