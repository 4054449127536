import React from 'react';

const Disclaimer: React.FC = () => {
  return (
    <div className="max-width mobile:mx-8 tablet:mx-40 laptop-standard:mx-10 desktop-standard:mx-56 desktop-large:mx-40 pb-15">
      <i>
        Please be aware that individuals not associated with Deutsch LA have fraudulently used our
        name and branding to solicit applications for fake jobs, to conduct fake job interviews,
        and/or make fake job offers.<br></br>
        <br></br>
        Deutsch LA will never contact you from a personal email account (@gmail, @hotmail, etc.) nor
        ask for money in exchange for recruitment services as background screenings, equipment
        costs, training courses, etc. No financial information will be requested for recruitment
        purposes (bank account, credit card details, etc.). Interviewers will not ask for
        confidential or personal information (driver’s license or passport, a social security
        number, credit card, or banking information). Interviews are conducted in person or via web
        conferencing, not through text or messaging apps (WhatsApp, Messenger, SMS, etc.).<br></br>
        <br></br>
        If you have provided personal information in connection with a fake job offer or interview
        and you are concerned, we suggest you take appropriate action and contact local law
        enforcement in your area. Please also reach out to us by sending an email to{' '}
        <a
          href="mailto:fraudreport@interpublic.com"
          target="_blank"
          rel="noreferrer"
          className="underline"
        >
          fraudreport@interpublic.com
        </a>
        .
      </i>
    </div>
  );
};

export default Disclaimer;
