import { StylesConfig } from 'react-select';

const styledCareers: StylesConfig<any, any, any> = {
  'indicatorsContainer': (base) => ({
    ...base,
    'paddingRight': '3%',
  }),
  'placeholder': (base) => ({
    ...base,
    'fontWeight': 400,
    'color': 'black',
    '@media only screen and (max-width: 640px)': {
      ...(base['@media only screen and (max-width: 640px)'] as Record<string, string>),
      'fontSize': '14px',
      'lineHeight': '16px',
    },
    '@media only screen and (min-width: 1024px)': {
      ...(base['@media only screen and (min-width: 1024px)'] as Record<string, string>),
      'fontSize': '18px',
      'lineHeight': '36px',
    },
    '@media only screen and (min-width: 1280px)': {
      ...(base['@media only screen and (min-width: 1280px)'] as Record<string, string>),
      'fontSize': '26px',
      'lineHeight': '36px',
    },
  }),
  'control': (base) => {
    return {
      ...base,
      'border': '1px solid #707070',
      'borderRadius': '50px',
      'width': '100%',
      'minHeight': '50px',
      'paddingLeft': '10px',
      '&:hover': { 'borderColor': '#707070' },
      'boxShadow': 'none',
      '@media only screen and (min-width: 1024px)': {
        ...(base['@media only screen and (min-width: 1024px)'] as Record<string, string>),
        'width': '309px',
        'height': '54px',
        'margin': 'auto',
      },
    };
  },
  'singleValue': (base) => ({
    ...base,
    'fontWeight': 400,
    'color': 'black',
    '@media only screen and (max-width: 640px)': {
      ...(base['@media only screen and (max-width: 640px)'] as Record<string, string>),
      'fontSize': '14px',
      'lineHeight': '16px',
    },
    '@media only screen and (min-width: 1024px)': {
      ...(base['@media only screen and (min-width: 1024px)'] as Record<string, string>),
      'fontSize': '18px',
      'lineHeight': '36px',
    },
    '@media only screen and (min-width: 1280px)': {
      ...(base['@media only screen and (min-width: 1280px)'] as Record<string, string>),
      'fontSize': '26px',
      'lineHeight': '36px',
    },
  }),
  'option': (base, state) => {
    return {
      ...base,
      'fontSize': '20px',
      'lineHeight': '46px',
      'fontWeight': 400,
      'color': 'black',
      'background': state.isSelected ? '#898686' : 'transparent',
      '&:hover': { 'background': '#E8E8E8' },
      '@media only screen and (max-width: 1024px)': {
        ...(base['@media only screen and (max-width: 1024px)'] as Record<string, string>),
        'fontSize': '14px',
        'lineHeight': '20px',
      },
    };
  },
  'menu': (base) => {
    return {
      ...base,
      'width': '100%',
      'borderRadius': '25px',
      'border': '1px solid #707070',
      'background': '#FFF',
      'paddingTop': '15px',
      'paddingBottom': '15px',
      '@media only screen and (min-width: 1024px)': {
        ...(base['@media only screen and (min-width: 1024px)'] as Record<string, string>),
        'width': '309px',
        'marginTop': '15px',
      },
    };
  },
};

export default styledCareers;
